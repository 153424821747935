(function () {
  'use strict';

  class ModuleSetTreeCtrl {
    constructor(ModuleSet, CloneModuleSetFlow, $state, $rootScope, $mdToast, $mdDialog, $window) {
      let vm = this;
      vm.search = {
        query: null
      };
      vm.ModuleSet = ModuleSet;
      vm.CloneModuleSetFlow = CloneModuleSetFlow;
      vm.$state = $state;
      vm.$window = $window;
      vm.$mdDialog = $mdDialog;
      vm.$rootScope = $rootScope;
      vm.$mdToast = $mdToast;
      vm.loading = false;
      vm.getModulesSet();
    }
    getModulesSet() {
      let vm = this;
      vm.loading = true;
      vm.ModuleSet.moduleSetIndex(vm.search)
        .then((data)=> {
          vm.loading = false;
          vm.moduleSet = data;
        });
    }
    beforeModule() {
      let vm = this;
      vm.search.page = vm.moduleSet.page - 1;
      vm.getModulesSet();
    }
    nextModule() {
      let vm = this;
      vm.search.page = vm.moduleSet.page + 1;
      vm.getModulesSet();
    }
    goToRoute(url, param, newindow) {
      let vm = this;
      if (newindow) {
        return vm.$window.open(vm.$state.href(url, param), '_blank');
      }
      vm.$state.go(url, param);
    }
    remove(scope) {
      scope.remove();
    }
    openMenu($mdMenu, ev) {
      $mdMenu.open(ev);
    }
    save(moduleSet) {
      let vm = this;
      vm.loading = true;
      vm.ModuleSet.myModuleSetUpdate(moduleSet)
        .then(()=> {
          vm.message('Module Set updated.');
          vm.getModulesSet();
        });
    }
    clone(e, moduleSet) {
      let vm = this;
      vm.CloneModuleSetFlow.show(e, moduleSet, (clone)=> {
        vm.ModuleSet.myModuleSetClone(moduleSet.id, clone)
          .then(()=> {
            vm.getModulesSet();
          })
          .catch((response) => {
            const {data: {
              error
            }} = response;
            angular.forEach(error, function (v) {
              vm.message(v.join(' '));
            });
          });
      });
    }
    stateEvent(moduleSet) {
      const vm = this;
      let state_event;
      if (moduleSet.state === 'locked') {
        state_event = 'unlock';
      } else {
        state_event = 'lock';
      }
      moduleSet.state_event = state_event;
      vm.save(moduleSet);
    }
    treeOptions() {
      return {
        accept: function () {
          return true;
        }
      };
    }
    message(msg) {
      let vm = this;
      vm.$mdToast.show(
        vm.$mdToast.simple()
          .textContent(msg)
          .position('bottom right')
          .hideDelay(3000)
      );
    }
    deleteModuleSet(module, ev) {
      let vm = this,
          confirm = vm.$mdDialog.confirm()
            .title('Confirm')
            .textContent('Are you sure you want to delete this?')
            .ariaLabel('Confirm')
            .targetEvent(ev)
            .ok('Delete')
            .cancel('Cancel');

      vm.$mdDialog.show(confirm).then(function () {
        vm.ModuleSet.myModuleSetDelete({id: module.id})
          .then(()=> {
            vm.message('Module Set deleted.');
            vm.getModulesSet();
          });
      });
    }
  }

  /**
   * @ngdoc object
   * @name learning.index.controller:ModuleSetTreeCtrl
   *
   * @description
   *
   */
  angular
    .module('components')
    .controller('ModuleSetTreeCtrl', ModuleSetTreeCtrl);
}());
